import { ReactNode } from 'react';

export type KeyValuePairProps = {
  title: string;
  value: ReactNode;
};

export function KeyValuePair(props: KeyValuePairProps) {
  const { title, value } = props;

  return (
    <div className="mb-4">
      <p className="font-semibold">{title}</p>
      <p>{value}</p>
    </div>
  );
}
