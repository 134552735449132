import { Link } from 'react-router';
import { TextLink } from './TextLink';

export function Footer() {
  return (
    <footer className="text-center p-6 text-dark">
      <p className="text-sm">
        © 2025{' '}
        <TextLink variant="primary" to="https://aiescape.io">
          AI Escape
        </TextLink>{' '}
        LLC - All Rights Reserved
      </p>
    </footer>
  );
}
