import { LoadingOrError } from './LoadingOrError';

export type LoadingViewProps = {
  children: React.ReactNode;
  loading?: boolean;
  error?: Error | null;
  retry?: () => void;
};

export function LoadingView({ children, loading, error, retry }: LoadingViewProps) {
  return (
    <div className="container mx-auto px-4 max-w-3xl">
      {loading || error ? (
        <LoadingOrError loading={loading} error={error} retry={retry} />
      ) : (
        children
      )}
    </div>
  );
}
