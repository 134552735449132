import { useMemo } from 'react';
import { VotesResponse } from '../../common/types';
import { calculateVoteStats } from '../../common/stats/votes';
import { Link } from 'react-router';
import { LoadingOrError } from '../../components/LoadingOrError';
import { useQuery } from '@tanstack/react-query';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { getUser } from '../../common/api/user';
import { KeyValuePair } from '../../components/KeyValuePair';
import { Container } from '../../components/Container';
import { MainLink } from '../../components/MainLink';
import { TextLink } from '../../components/TextLink';
import { ColumnView } from '../../components/ColumnView';
import { LoadingView } from '../../components/LoadingView';
import { StatsList } from '../../components/StatsList';

export type UserViewProps = {
  votes?: VotesResponse[];
};

export default function UserView(props: UserViewProps) {
  const { votes } = props;
  const stats = useMemo(() => calculateVoteStats(votes), [votes]);
  const session = useSessionContext();

  const {
    isPending,
    isFetching,
    error,
    data: user,
    refetch,
  } = useQuery({
    queryKey: ['user', !session.loading ? session.userId : undefined],
    queryFn: async () => await getUser(),
    staleTime: 0,
    refetchOnWindowFocus: false,
    enabled: !session.loading && session.doesSessionExist,
  });

  const loading = (isPending || isFetching || session.loading) && !error;

  return (
    <ColumnView columns={2}>
      <div>
        <LoadingView loading={loading} error={error} retry={refetch}>
          {user && (
            <Container>
              <h1 className="text-2xl font-bold mb-4 text-dark">Your Account</h1>
              <KeyValuePair
                title="Username"
                value={
                  // user.username
                  <TextLink
                    to={`/users/${user.username}`}
                    // variant="primary" // if you want a colored link
                    className="underline"
                  >
                    {user.username}
                  </TextLink>
                }
              />
              {user.email && <KeyValuePair title="Email" value={user.email} />}
              {user.name && <KeyValuePair title="Name" value={user.name} />}
              <KeyValuePair title="Joined" value={new Date(user.createdAt).toLocaleDateString()} />
              <div className="grid grid-cols-1">
                <MainLink to="/user/update" variant="secondary">
                  <span className="text-xl font-semibold">Update</span>
                </MainLink>
              </div>
            </Container>
          )}
        </LoadingView>
      </div>
      <Container>
        <h1 className="text-2xl font-bold mb-4 text-dark">Your Stats</h1>
        <LoadingView loading={loading} error={error} retry={refetch}>
          {stats ? (
            <StatsList stats={stats} />
          ) : (
            <div className="flex flex-col gap-2">
              <p className="text-dark">No votes yet</p>
            </div>
          )}
        </LoadingView>
      </Container>
    </ColumnView>
  );
}
