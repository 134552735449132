export type ColumnViewProps = {
  children: React.ReactNode;
  columns?: number;
};

const colLookup: Record<number, string> = {
  1: 'grid-cols-1',
  2: 'grid-cols-1 sm:grid-cols-2',
  3: 'grid-cols-1 sm:grid-cols-3',
  4: 'grid-cols-1 sm:grid-cols-4',
};

export function ColumnView({ children, columns }: ColumnViewProps) {
  const columnClass = columns && columns > 1 ? colLookup[columns] || 'grid-cols-1' : 'grid-cols-1';

  return (
    <div className={`container mx-auto px-4 max-w-2xl grid ${columnClass} gap-4 text-dark`}>
      {children}
    </div>
  );
}
