import { TextLink } from '../../components/TextLink';
import { SnakeText } from '../../components/text/SnakeText';
import { BigQuotes } from '../../components/text/BigQuotes';
import { ColumnView } from '../../components/ColumnView';

export default function PrivacyPolicy() {
  return (
    <ColumnView>
      <h1 className="text-3xl font-bold">
        Privacy Policy for{' '}
        <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong>
      </h1>

      <p>
        At <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong>, we respect
        your privacy and are committed to protecting the personal information you share with us.
        This Privacy Policy describes how we collect, use, and safeguard your information.
      </p>

      <h2 className="text-xl font-bold">1. Information We Collect</h2>
      <p>
        <em className="underline">Personal Information:</em> When you sign up or create an account,
        we may ask for your name and email address (<em>“User Data”</em>). This allows us to
        identify your account and provide personalized features such as saving your quiz/vote
        history.
      </p>

      <h2 className="text-xl font-bold">2. How We Use Your Information</h2>
      <p>
        <strong>a. Account Management:</strong> Your email and name enable us to manage your
        account, provide login access, and communicate with you about your gameplay, updates, or
        promotional events.
      </p>
      <p>
        <strong>b. Improving the Service:</strong> We may analyze aggregated user data (e.g., game
        performance and usage patterns) to improve{' '}
        <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong> and develop new
        features.
      </p>
      <p>
        <strong>c. Communication:</strong> We may use your email to send occasional updates or
        announcements. You can opt out of receiving marketing emails by following the unsubscribe
        link in each communication.
      </p>

      <h2 className="text-xl font-bold">3. Cookies & Tracking Technologies</h2>
      <p>
        We may use cookies or similar technologies to remember your preferences, maintain session
        state, and provide a consistent experience. You can disable cookies in your browser, but
        doing so may limit some functionalities of the game.
      </p>

      <h2 className="text-xl font-bold">4. Data Sharing & Disclosure</h2>
      <p>
        We do not sell or rent your personal information to third parties. We may share certain
        aggregated, non-personal data with partners or service providers to help us analyze and
        improve our service.
      </p>
      <p>
        In rare instances, we may disclose your information to comply with legal obligations,
        enforce our Terms of Service, or protect the rights and safety of{' '}
        <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong>,{' '}
        <strong className="px-1 py-0 bg-dark text-light"> AI Escape</strong>, and our users.
      </p>

      <h2 className="text-xl font-bold">5. Data Security</h2>
      <p>
        We take reasonable measures to protect your information from unauthorized access or
        disclosure. However, no method of transmission over the internet or electronic storage is
        100% secure, and we cannot guarantee absolute security.
      </p>

      <h2 className="text-xl font-bold">6. Children’s Privacy</h2>
      <p>
        <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong> is not intended
        for children under the age of 13 without parental consent. If we become aware that we have
        collected personal information from a child under 13 without verification of parental
        consent, we will take steps to remove that information from our servers.
      </p>

      <h2 className="text-xl font-bold">7. Third-Party Links & AI-Generated Content</h2>
      <p>
        Our site may contain links to third-party websites or display content generated by AI. We do
        not control these websites or AI outputs and are not responsible for their privacy
        practices. We encourage you to review the privacy policies of any third-party sites you
        visit.
      </p>

      <h2 className="text-xl font-bold">8. Changes to This Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be effective upon
        posting on our website. Your continued use of the service after the posting of an updated
        policy constitutes acceptance of those changes.
      </p>

      <h2 className="text-xl font-bold">9. Contact Us</h2>
      <p>
        If you have questions or concerns about this Privacy Policy, please email us at{' '}
        <TextLink variant="primary" to="mailto:max@aiescape.io" className="underline">
          max@aiescape.io
        </TextLink>
        .
      </p>

      <p className="mt-4">
        <em>
          Thank you for taking the time to read our Privacy Policy. We value your trust and strive
          to keep your data safe while you have fun spotting <SnakeText>fake</SnakeText> headlines.
        </em>
      </p>
    </ColumnView>
  );
}
