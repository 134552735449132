import React from 'react';
import './snake.css';

type SnakeTextProps = {
  children: string;
};

export function SnakeText({ children }: SnakeTextProps) {
  return (
    <span className="snake-text">
      {children.split(' ').map((word, wordIndex) => (
        <span key={wordIndex} className="snake-word">
          {word.split('').map((char, charIndex) => (
            <span
              key={charIndex}
              className="snake-char"
              style={{ animationDelay: `${wordIndex * 0.5 + charIndex * 0.07}s` }} // 0.5s delay between words, 0.07s offset per letter
            >
              {char}
            </span>
          ))}{' '}
          {/* Add a space after each word */}
        </span>
      ))}
    </span>
  );
}
