import { Route, Routes } from 'react-router';
import * as reactRouterDom from 'react-router';
import { useEffect, useRef, useState } from 'react';

import HomePage from './pages/home';
import LeaderboardPage from './pages/leaderboard';
import ErrorPage from './pages/error';
import AboutPage from './pages/about';
import VotePage from './pages/vote';
import VoteCategoryPage from './pages/vote/category';
import LogoutPage from './pages/logout';
import TermsOfService from './pages/terms';
import PrivacyPolicy from './pages/privacy';
import { ScrollToTop } from './components/ScrollToTop';

import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import { ThirdPartyPreBuiltUI } from 'supertokens-auth-react/recipe/thirdparty/prebuiltui';
// import { EmailVerificationPreBuiltUI } from 'supertokens-auth-react/recipe/emailverification/prebuiltui';
import { AuthRecipeComponentsOverrideContextProvider } from 'supertokens-auth-react/ui';
import { EmailPasswordComponentsOverrideProvider } from 'supertokens-auth-react/recipe/emailpassword';
import { ThirdpartyComponentsOverrideProvider } from 'supertokens-auth-react/recipe/thirdparty';

import { SessionAuth, useSessionContext } from 'supertokens-auth-react/recipe/session';
import { NavBar } from './components/NavBar';

import './global.css';
import { useQuery } from '@tanstack/react-query';
import { getVotes } from './common/api/votes';
import { VotesResponse } from './common/types';
import UserView from './pages/user';
import ProfileView from './pages/profile';
import UserUpdateView from './pages/user/update';
import { Footer } from './components/Footer';
import { TextLink } from './components/TextLink';

function AppElements() {
  const session = useSessionContext();
  const { data: votes } = useQuery({
    queryKey: ['votes', !session.loading ? session.userId : undefined],
    queryFn: async () => await getVotes(),
    staleTime: 0,
    placeholderData: (prev) => prev,
    refetchOnWindowFocus: false,
    enabled: !session.loading && session.doesSessionExist,
  });
  const [liveVotes, setLiveVotes] = useState<VotesResponse[] | undefined>();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLiveVotes(votes);
  }, [votes]);

  return (
    <div
      ref={scrollContainerRef}
      className="flex-1 flex flex-col overflow-y-auto overflow-x-hidden"
    >
      <ScrollToTop scrollContainerRef={scrollContainerRef} />
      <NavBar votes={liveVotes} />
      <div className="flex-1 flex flex-col justify-between">
        <main className="pt-8">
          <Routes>
            {/*This renders the login UI on the /auth route*/}
            {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
              EmailPasswordPreBuiltUI,
              ThirdPartyPreBuiltUI,
              // EmailVerificationPreBuiltUI,
            ])}
            {/*Your app routes*/}

            {/* Protected route example */}
            <Route
              path="/vote"
              element={
                <SessionAuth>
                  <VotePage setLiveVotes={setLiveVotes} />
                </SessionAuth>
              }
            />
            <Route
              path="/vote/category/:name"
              element={
                <SessionAuth>
                  <VoteCategoryPage setLiveVotes={setLiveVotes} />
                </SessionAuth>
              }
            />
            <Route
              path="/user"
              element={
                <SessionAuth>
                  <UserView votes={liveVotes} />
                </SessionAuth>
              }
            />
            <Route
              path="/user/update"
              element={
                <SessionAuth>
                  <UserUpdateView />
                </SessionAuth>
              }
            />
            <Route path="/users/:username" element={<ProfileView />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/leaderboard" element={<LeaderboardPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route element={<ErrorPage />} path="*" />
          </Routes>
        </main>
        <Footer />
      </div>
    </div>
  );
}

function App() {
  return (
    <AuthRecipeComponentsOverrideContextProvider
      components={{
        AuthPageHeader_Override: ({ DefaultComponent, ...props }) => {
          return <DefaultComponent {...props} />;
        },
      }}
    >
      <EmailPasswordComponentsOverrideProvider
        components={{
          EmailPasswordSignUpForm_Override: ({ DefaultComponent, ...props }) => {
            return (
              <div>
                {/* Render the default footer if you want the default "Already have an account?" stuff */}
                <DefaultComponent {...props} />

                {/* Then add your custom text / disclaimer */}
                <div className="my-4 text-center text-sm text-dark">
                  By signing up, you agree to our{' '}
                  <TextLink to="/terms" className="underline hover:text-primary">
                    Terms of Service
                  </TextLink>{' '}
                  and{' '}
                  <TextLink to="/privacy" className="underline hover:text-primary">
                    Privacy Policy
                  </TextLink>
                  .
                </div>
              </div>
            );
          },
          EmailPasswordSignInForm_Override: ({ DefaultComponent, ...props }) => {
            return (
              <div>
                {/* Render the default footer if you want the default "Already have an account?" stuff */}
                <DefaultComponent {...props} />

                {/* Then add your custom text / disclaimer */}
                <div className="my-4 text-center text-sm text-dark">
                  By signing in, you agree to our{' '}
                  <TextLink to="/terms" className="underline hover:text-primary">
                    Terms of Service
                  </TextLink>{' '}
                  and{' '}
                  <TextLink to="/privacy" className="underline hover:text-primary">
                    Privacy Policy
                  </TextLink>
                  .
                </div>
              </div>
            );
          },
        }}
      >
        <ThirdpartyComponentsOverrideProvider
          components={{
            ThirdPartySignInAndUpProvidersForm_Override: ({ DefaultComponent, ...props }) => {
              // optionally override the third party providers list..
              return <DefaultComponent {...props} />;
            },
          }}
        >
          {/* Rest of the JSX */}
          <div className="h-[100dvh] flex flex-col overflow-hidden">
            <AppElements />
          </div>
        </ThirdpartyComponentsOverrideProvider>
      </EmailPasswordComponentsOverrideProvider>
    </AuthRecipeComponentsOverrideContextProvider>
  );
}

export default App;
