import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router';
import { I18nProvider } from '@cloudscape-design/components/i18n';
import enMessages from '@cloudscape-design/components/i18n/messages/all.en';
import { QueryClient, QueryClientProvider, QueryCache, MutationCache } from '@tanstack/react-query';

import App from './App';
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import ThirdParty, { Google, Discord, Facebook } from 'supertokens-auth-react/recipe/thirdparty';

import Session from 'supertokens-auth-react/recipe/session';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import MicrosoftIcon from './components/MicrosoftIcon';
import EmailVerification from 'supertokens-auth-react/recipe/emailverification';
import { TextLink } from './components/TextLink';

const environment = process.env.NODE_ENV === 'development' ? 'development' : 'production';
const isProduction = process.env.NODE_ENV === 'production';

const queryClient = new QueryClient({
  //   queryCache: new QueryCache({
  //     onError: (error, query) => {
  //       const { title, description } = getErrorDetails(error);
  //       // Report the error to Sentry
  //       Sentry.captureException(error, {
  //         tags: {
  //           queryKey: `${query.queryKey}`,
  //           title,
  //           description,
  //         },
  //       });
  //     },
  //   }),
  //   mutationCache: new MutationCache({
  //     onError: (error) => {
  //       const { title, description } = getErrorDetails(error);
  //       // Report the error to Sentry
  //       Sentry.captureException(error, {
  //         tags: {
  //           title,
  //           description,
  //         },
  //       });
  //     },
  //   }),
});

// 1. Init SuperTokens
SuperTokens.init({
  appInfo: {
    appName: 'Real Fake News',
    apiDomain: isProduction ? 'https://api.real-fake.news' : 'http://localhost:80',
    websiteDomain: isProduction ? 'https://www.real-fake.news' : 'http://localhost:3000',
    apiBasePath: '/auth',
    websiteBasePath: '/auth',
  },
  useShadowDom: false,
  style: `
    a[data-supertokens~="superTokensBranding"] {
        display: none;
    }
  `,
  recipeList: [
    ThirdParty.init({
      signInAndUpFeature: {
        providers: [
          Google.init(),
          {
            id: 'microsoft',
            name: 'Microsoft',
            buttonComponent: (props: { name: string }) => (
              <button data-supertokens="button providerButton providerMicrosoft">
                <div data-supertokens="providerButtonLeft">
                  <div data-supertokens="providerButtonLogo">
                    <div data-supertokens="providerButtonLogoCenter">
                      <MicrosoftIcon />
                    </div>
                  </div>
                </div>
                <div data-supertokens="providerButtonText">
                  <span>{'Continue with ' + props.name}</span>
                </div>
              </button>
            ),
          },
          Facebook.init(),
          Discord.init(),
        ],
      },
    }),
    EmailPassword.init(),
    Session.init(),
  ],
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <SuperTokensWrapper>
    <BrowserRouter>
      <I18nProvider locale="en" messages={[enMessages]}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </I18nProvider>
    </BrowserRouter>
  </SuperTokensWrapper>,
);
