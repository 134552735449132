import { useState } from 'react';
import { useMemo } from 'react';
import { VotesResponse } from '../common/types';
import { Modal } from './Modal';
import { Link, useNavigate } from 'react-router';
import { calculateVoteStats } from '../common/stats/votes';
import { MainLink } from './MainLink';
import { Spinner } from '@cloudscape-design/components';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { getUser } from '../common/api/user';
import { useQuery } from '@tanstack/react-query';

export type VoteStatsViewProps = {
  votes?: VotesResponse[];
};

export function VoteStatsView(props: VoteStatsViewProps) {
  const session = useSessionContext();
  const { votes } = props;
  const stats = useMemo(() => calculateVoteStats(votes), [votes]);
  const [showStats, setShowStats] = useState(false);
  const isLoggedIn = !session.loading && session.doesSessionExist;
  const navigate = useNavigate();

  const {
    isPending,
    isFetching,
    error: errorUser,
    data: user,
    refetch,
  } = useQuery({
    queryKey: ['user', !session.loading ? session.userId : undefined],
    queryFn: async () => await getUser(),
    staleTime: 0,
    refetchOnWindowFocus: false,
    enabled: isLoggedIn,
  });

  const loadingUser = (isPending || isFetching) && !errorUser;

  return (
    <div>
      <button
        onClick={() => {
          if (isLoggedIn) {
            setShowStats(true);
          } else {
            setShowStats(false);
            navigate('/auth');
          }
        }}
        className="px-4 py-2 text-center rounded hover:brightness-110 transition hover:bg-primary"
      >
        {!isLoggedIn ? (
          <span className="text-white text-xs sm:text-base">Login</span>
        ) : votes === undefined || session.loading ? (
          <Spinner size="normal" variant="inverted" className="text-white" />
        ) : stats ? (
          <span className="text-white text-xs sm:text-base">
            {stats.correct}/{stats.total} ({stats.accuracy}%)
          </span>
        ) : (
          <span className="text-white text-xs sm:text-base">-</span>
        )}
      </button>
      <Modal isOpen={showStats} onClose={() => setShowStats(false)}>
        <div className="flex flex-col gap-4">
          {stats && !session.loading && votes !== undefined ? (
            <div className="flex flex-col gap-2">
              <h1 className="text-2xl font-bold mb-2 text-dark">Stats</h1>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                <div className="flex flex-col gap-1 text-dark">
                  <h2 className="text-xl font-bold mb-1 text-dark">Overall</h2>
                  <p>Correct: {stats.correct}</p>
                  <p>Incorrect: {stats.incorrect}</p>
                  <p>Total: {stats.total}</p>
                  <p>Accuracy: {stats.accuracy}%</p>
                </div>
                <div className="flex flex-col gap-1 text-dark">
                  <h2 className="text-xl font-bold mb-1 text-dark">Fake Articles</h2>
                  <p>Correct: {stats.fakeCorrect}</p>
                  <p>Incorrect: {stats.fakeIncorrect}</p>
                  <p>Total: {stats.fakeJudged}</p>
                  <p>Accuracy: {stats.fakeAccuracy}%</p>
                </div>
                <div className="flex flex-col gap-1 text-dark">
                  <h2 className="text-xl font-bold mb-1 text-dark">Real Articles</h2>
                  <p>Correct: {stats.realCorrect}</p>
                  <p>Incorrect: {stats.realIncorrect}</p>
                  <p>Total: {stats.realJudged}</p>
                  <p>Accuracy: {stats.realAccuracy}%</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              {session.loading || votes === undefined ? (
                <Spinner size="normal" />
              ) : (
                <p className="text-dark">No votes yet</p>
              )}
            </div>
          )}
          {isLoggedIn && !loadingUser && user && (
            <div className="flex flex-col gap-2 items-center">
              <div className="flex flex-col gap-2 items-center sm:w-64 w-full">
                <MainLink
                  to={`/users/${user.username}`}
                  variant="secondary"
                  onClick={() => setShowStats(false)}
                  className="w-full"
                >
                  <span className="text-xl font-semibold">Profile</span>
                </MainLink>

                <MainLink
                  to="/user"
                  variant="light"
                  onClick={() => setShowStats(false)}
                  className="w-full"
                >
                  <span className="text-xl font-semibold">Account Info</span>
                </MainLink>
                <MainLink
                  to="/logout"
                  variant="primary"
                  onClick={() => setShowStats(false)}
                  className="w-full"
                >
                  <span className="text-xl font-semibold">Logout</span>
                </MainLink>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
