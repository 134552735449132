import React from 'react';
import { Link } from 'react-router';

export type MainLinkProps = {
  to: string;
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'light';
  className?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

export function MainLink(props: MainLinkProps) {
  const { to, children, variant = 'primary', className, onClick } = props;

  // We'll base our styling on the variant
  // For example, "primary" is pastel pink, "secondary" might be pastel blue
  const baseStyles = `
    px-4 py-2 rounded shadow font-semibold text-dark
    hover:brightness-110 transition
    pressable
    text-center
    ${className || ''}
  `;
  const variantStyles =
    variant === 'primary'
      ? 'bg-secondary hover:bg-accent' // e.g., pink -> peach on hover
      : variant === 'secondary'
      ? 'bg-primary hover:bg-accent'
      : 'bg-light hover:bg-accent';

  return (
    <Link to={to} className={`${baseStyles} ${variantStyles}`} onClick={onClick}>
      {children}
    </Link>
  );
}
