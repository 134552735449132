import { ColumnView } from '../../components/ColumnView';
import { Container } from '../../components/Container';
import { MainLink } from '../../components/MainLink';

export default function View() {
  return (
    <ColumnView>
      <div className="flex flex-col items-center justify-center mt-16">
        <Container>
          <h1 className="text-xl font-bold m-4">404 - Page Not Found</h1>
          <p className="m-4">The page you are looking for does not exist.</p>
        </Container>
      </div>
      <div className="flex justify-center mt-2 mb-2">
        <MainLink to="/" variant="primary" className="w-64">
          <span className="text-lg font-semibold">Go Home</span>
        </MainLink>
      </div>
    </ColumnView>
  );
}
