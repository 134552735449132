import { MainLink } from '../../components/MainLink';
import { SnakeText } from '../../components/text/SnakeText';
import { BigQuotes } from '../../components/text/BigQuotes';
import { TextLink } from '../../components/TextLink';
import { ColumnView } from '../../components/ColumnView';

export default function View() {
  return (
    <ColumnView>
      <div className="flex justify-center bg-light rounded-sm p-1">
        <img
          src="https://cdn.aiescape.io/real-fake-news/icon.svg"
          alt="Real Fake News Logo"
          className="w-64 h-auto rounded text-light bg-white"
        />
      </div>
      <h1 className="text-2xl font-bold">
        About <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong>
      </h1>

      <h2 className="text-xl font-bold">Inspiration</h2>

      <p>
        <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong> was inspired by
        a desire to help people sharpen their skills at spotting <SnakeText>fake</SnakeText>{' '}
        headlines - especially ones that could be <em>misleading</em> or downright{' '}
        <strong className="px-1 py-0 bg-secondary text-light">harmful</strong>. In a world
        overflowing with information, it’s crucial to develop a critical eye for what’s real and
        what’s simply <strong>too good to be true</strong>.
      </p>

      <p>
        Discussions surrounding <BigQuotes>fake news</BigQuotes> have been growing in recent years.
        However, it is really hard to discuss <BigQuotes>fake news</BigQuotes> without involving{' '}
        <SnakeText>politics</SnakeText> and <strong>trust</strong> in the media. Everyone has their
        own <em>biases</em>. It’s hard to have a neutral, factual discussion concerning{' '}
        <em className="underline">factually inaccurate news</em> without coming across as trying to
        score one for your team.
      </p>

      <h2 className="text-xl font-bold mt-4">How it works</h2>

      <p>
        <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong> is an attempt to
        create a neutral, factual discussion about <em className="underline">fake news</em> without
        involving <SnakeText>politics</SnakeText> or <strong>trust</strong> in the media. Here, we
        focus on making the{' '}
        <strong className="px-1 py-0 bg-secondary text-light">big bad liar</strong> the{' '}
        <strong>AI</strong>, and the <em className="underline">human authors</em> the{' '}
        <strong className="px-1 py-0 bg-primary text-light">heroes</strong>. This helps us focus on
        the known, objectively <em className="underline">factually inaccurate news</em> itself
        (created by the AI), without involving all the other stuff.
      </p>

      <p>
        The way it works is that we collect top news headlines from a variety of reputable sources,
        and then we use AI to generate <em className="underline">factually inaccurate</em> headlines
        that are <SnakeText>similar</SnakeText>. We then ask you to guess which headlines are{' '}
        <strong className="px-1 py-0 bg-secondary text-light">fake</strong> and which are{' '}
        <strong className="px-1 py-0 bg-primary text-light">real</strong>. Each time you are
        presented with a headline, there’s a 50% chance it’s{' '}
        <strong className="px-1 py-0 bg-secondary text-light">fake</strong> and a 50% chance it’s{' '}
        <strong className="px-1 py-0 bg-primary text-light">real</strong>. You can play as many
        times as you want, and we’ll keep track of your score!
      </p>

      <h2 className="text-xl font-bold mt-4">About us</h2>

      <p>
        I’m Max, a Natural Language Processing researcher focused on framing,{' '}
        <strong className="px-1 py-0 bg-secondary text-light">misinformation</strong>, AI, public
        health, and social media. If you’re curious about my work, feel free to check out my
        personal site at{' '}
        <TextLink variant="primary" to="https://maxnlp.ai" external className="underline">
          maxnlp.ai
        </TextLink>
        . This project is one of the many initiatives under the{' '}
        <strong className="px-1 py-0 bg-dark text-light">AI Escape</strong> brand - where we build
        AI-driven products and experiences to educate, entertain, and solve real-world problems. For
        example, we’ve built an{' '}
        <TextLink variant="primary" to="https://rooms.aiescape.io" external className="underline">
          AI Escape Room
        </TextLink>{' '}
        platform where AI can design and run custom virtual escape rooms.
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 justify-center gap-4 sm:gap-0">
        <div className="flex justify-center">
          <img
            src="https://cdn.aiescape.io/logo-assistant.svg"
            alt="AI Escape Logo"
            className="w-64 h-auto rounded bg-dark text-light border-dark border-2"
          />
        </div>

        <p>
          You can learn more about our overall offerings and mission at{' '}
          <TextLink variant="primary" to="https://aiescape.io" external className="underline">
            aiescape.io
          </TextLink>
          , or explore how we can help your business or organization via{' '}
          <TextLink
            variant="primary"
            to="https://consulting.aiescape.io"
            external
            className="underline"
          >
            consulting.aiescape.io
          </TextLink>
          . If you’d like to contact me directly, send an email to{' '}
          <TextLink variant="primary" to="mailto:max@aiescape.io" className="underline">
            max@aiescape.io
          </TextLink>
          . I’m always happy to discuss ways we can collaborate!
        </p>
      </div>

      <h2 className="text-xl font-bold mt-4">Final thoughts</h2>

      <p>
        Ultimately, <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong> aims
        to be equal parts fun and educational. Whether you’re a casual player looking for a quirky
        challenge or a budding fact-checker honing your skills, we hope this platform inspires you
        to question headlines just a bit <em>more</em> - and to become a more discerning consumer of
        news. We also believe it is important that the public be aware of how downright{' '}
        <em>convincing</em> AI can be - especially in the context of news headlines.
      </p>
      <p>We hope you enjoy the game, and we also hope you’ll learn something from it. Have fun!</p>

      <div className="flex justify-center mt-2 mb-2">
        <MainLink to="/vote" variant="primary" className="w-64">
          <span className="text-lg font-semibold">Play Now</span>
        </MainLink>
      </div>
    </ColumnView>
  );
}
