import { useEffect, useMemo, useState } from 'react';
import { LoadingOrError } from '../../../components/LoadingOrError';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { getUser, updateUser } from '../../../common/api/user';
import { KeyValuePair } from '../../../components/KeyValuePair';
import { Container } from '../../../components/Container';
import { MainLink } from '../../../components/MainLink';
import { MainButton } from '../../../components/MainButton';
import { User, UserUpdateRequest } from '../../../common/types';
import { MainInput } from '../../../components/MainInput';
import { useNavigate } from 'react-router';
import { ColumnView } from '../../../components/ColumnView';

export default function UserUpdateView() {
  const session = useSessionContext();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [user, setUser] = useState<User | null>(null);

  // TODO might want to move user into a global state
  const { isPending, isFetching, error, data, refetch } = useQuery({
    queryKey: ['user', !session.loading ? session.userId : undefined],
    queryFn: async () => await getUser(),
    staleTime: 0,
    refetchOnWindowFocus: false,
    enabled: !session.loading && session.doesSessionExist,
  });

  const save = useMutation({
    mutationFn: async (req: UserUpdateRequest) => await updateUser(req),
    onSuccess: () => {
      navigate('/user');
    },
  });

  useEffect(() => {
    if (user) {
      setUsername(user.username);
    }
  }, [user]);

  useEffect(() => {
    if (data) {
      setUser(data);
    }
  }, [data]);

  const loading = (isPending || isFetching) && !error;

  const handleSave = async () => {
    const req: UserUpdateRequest = { username: username.trim() };
    await save.mutateAsync(req);
  };

  return (
    <ColumnView columns={2}>
      <div>
        {loading || error ? (
          <LoadingOrError loading={loading} error={error} retry={refetch} />
        ) : user ? (
          <Container>
            <h1 className="text-2xl font-bold mb-4 text-dark">Update Your Account</h1>
            <div className="grid grid-cols-1">
              <KeyValuePair
                title="Username"
                value={
                  <MainInput
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    className="w-full"
                  />
                }
              />
            </div>
            {/* {user.email && <KeyValuePair title="Email" value={user.email} />} */}
            {/* {user.name && <KeyValuePair title="Name" value={user.name} />} */}
            <div className="grid grid-cols-1 gap-4">
              <MainButton
                onClick={handleSave}
                variant="secondary"
                disabled={username === user.username || save.isPending}
              >
                <span className="text-xl font-semibold">Save</span>
              </MainButton>
              <MainLink to="/user" variant="primary">
                <span className="text-xl font-semibold">Cancel</span>
              </MainLink>
              {save.isPending || save.error ? (
                <LoadingOrError loading={save.isPending} error={save.error} retry={handleSave} />
              ) : null}
            </div>
          </Container>
        ) : null}
      </div>
    </ColumnView>
  );
}
