import { useMemo } from 'react';
import { Category } from '../common/types';

export type CategoryItemProps = {
  category: Category;
};

export function CategoryIcon(props: CategoryItemProps) {
  const { category } = props;
  const formattedName = useMemo(() => {
    // split by whitespace and capitalize each word
    return category.name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }, [category.name]);

  return (
    <div className="px-2 py-1 rounded shadow text-center bg-dark w-30">
      <span className="text-light text-xs">{formattedName}</span>
    </div>
  );
}
