import { useEffect } from 'react';
import { signOut } from 'supertokens-auth-react/recipe/session';

import { useNavigate } from 'react-router';
import { ColumnView } from '../../components/ColumnView';
import { Container } from '../../components/Container';

export default function View() {
  const navigate = useNavigate();

  async function logout() {
    await signOut();
    navigate('/');
  }

  useEffect(() => {
    logout();
  }, []);

  // TODO: Show a loading page

  return (
    <ColumnView>
      <div className="flex flex-col items-center justify-center mt-16">
        <Container>
          <h1 className="text-xl font-bold m-4">Logging out...</h1>
        </Container>
      </div>
    </ColumnView>
  );
}
