import { VoteStats } from '../common/stats/votes';

export function StatsList({ stats }: { stats: VoteStats }) {
  return (
    <div className="flex flex-col gap-2">
      <div className="grid grid-cols-1 gap-4">
        <div className="flex flex-col gap-1 text-dark">
          <h2 className="text-xl font-bold mb-1 text-dark">Overall</h2>
          <p>Correct: {stats.correct}</p>
          <p>Incorrect: {stats.incorrect}</p>
          <p>Total: {stats.total}</p>
          <p>Accuracy: {stats.accuracy}%</p>
        </div>
        <div className="flex flex-col gap-1 text-dark">
          <h2 className="text-xl font-bold mb-1 text-dark">Fake Articles</h2>
          <p>Correct: {stats.fakeCorrect}</p>
          <p>Incorrect: {stats.fakeIncorrect}</p>
          <p>Total: {stats.fakeJudged}</p>
          <p>Accuracy: {stats.fakeAccuracy}%</p>
        </div>
        <div className="flex flex-col gap-1 text-dark">
          <h2 className="text-xl font-bold mb-1 text-dark">Real Articles</h2>
          <p>Correct: {stats.realCorrect}</p>
          <p>Incorrect: {stats.realIncorrect}</p>
          <p>Total: {stats.realJudged}</p>
          <p>Accuracy: {stats.realAccuracy}%</p>
        </div>
      </div>
    </div>
  );
}
