import { Spinner } from '@cloudscape-design/components';
import { AxiosError } from 'axios';
import { MainButton } from './MainButton';
import { Container } from './Container';

export type ErrorDetails = {
  title: string;
  description: string;
};

export function getErrorDetails(error: Error) {
  let errorTitle = '';
  let errorDescription = '';

  if (error instanceof AxiosError && error.response) {
    errorTitle = `${error.response.status} ${error.response.statusText}`;

    if (error.response.data?.detail) {
      errorDescription = `${error.response.data.detail}`;
    } else {
      errorDescription = error?.message ?? 'An unknown error occurred';
    }
  } else {
    errorTitle = 'An error occurred';
    errorDescription = error?.message ?? 'An unknown error occurred';
  }

  return {
    title: errorTitle,
    description: errorDescription,
  };
}

export type LoadingOrErrorProps = {
  loading?: boolean;
  retry?: () => void;
  error?: Error | null;
  hideText?: boolean;
};

export function LoadingOrError(props: LoadingOrErrorProps) {
  const { loading, retry, error, hideText } = props;

  const content = hideText ? (
    <Spinner size="big" variant={error ? 'inverted' : 'normal'} />
  ) : (
    // <Box textAlign="center">
    <div className="flex flex-col items-center justify-center">
      {!error ? (
        <div className="text-sm font-bold">Loading...</div>
      ) : (
        <div className="text-sm font-bold">
          <br />
        </div>
      )}
      <Spinner size="large" variant={error ? 'inverted' : 'normal'} />
    </div>
  );
  const { title, description } = error ? getErrorDetails(error) : { title: '', description: '' };

  return (
    <div className="grid grid-cols-1 gap-1">
      {error ? (
        <Container className="grid grid-cols-1 gap-1 outline outline-2 outline-secondary">
          <div className="text-lg font-bold">Error</div>
          <div className="text-sm">{description}</div>
          <div className="text-xs">{title}</div>
          <MainButton disabled={loading} onClick={retry} className="mt-2">
            <span className="text-md font-semibold">Retry</span>
          </MainButton>
        </Container>
      ) : (
        content
      )}
    </div>
  );
}
