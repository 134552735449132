import { RandomArticleResponse } from '../types';
import axiosInstance from './axiosInstance';

export async function getRandomArticle() {
  const response = await axiosInstance.get('/articles/random');

  return response.data as RandomArticleResponse;
}

export async function getRandomCategoryArticle(name: string) {
  const response = await axiosInstance.get(`/articles/random/${name}`);

  return response.data as RandomArticleResponse;
}
