import React from 'react';
import { LeaderboardResponse } from '../common/types';
// If you prefer using MainLink or another component, just swap it here:
import { TextLink } from '../components/TextLink';

export type LeaderboardListProps = {
  items: LeaderboardResponse[];
};

export function LeaderboardList({ items }: LeaderboardListProps) {
  if (!items || items.length === 0) {
    return (
      <div className="p-4 bg-white/80 rounded shadow text-dark">
        <p>No leaderboard data yet.</p>
      </div>
    );
  }

  return (
    <div className="overflow-x-auto w-full text-dark">
      <table className="min-w-full table-auto border-collapse">
        <thead>
          <tr className="bg-primary/10">
            <th className="px-4 py-2 text-left text-sm font-semibold">Rank</th>
            <th className="px-4 py-2 text-left text-sm font-semibold">User</th>
            <th className="px-4 py-2 text-left text-sm font-semibold">Skill Score</th>
            <th className="px-4 py-2 text-left text-sm font-semibold">Accuracy</th>
            <th className="px-4 py-2 text-left text-sm font-semibold">Votes</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr
              key={item.username}
              className="border-b last:border-0 hover:bg-secondary/10 transition"
            >
              {/* Rank (index + 1) */}
              <td className="px-4 py-2 text-sm">{index + 1}</td>

              {/* Username as a link to /users/{username} */}
              <td className="px-4 py-2 text-sm">
                <TextLink
                  to={`/users/${item.username}`}
                  // variant="primary" // if you want a colored link
                  className="underline"
                >
                  {item.username}
                </TextLink>
              </td>

              {/* Lower Bound */}
              <td className="px-4 py-2 text-sm">{Math.round(item.posterior_lower_bound * 100)}%</td>

              {/* Accuracy */}
              <td className="px-4 py-2 text-sm">{Math.round(item.accuracy * 100)}%</td>

              {/* Votes */}
              <td className="px-4 py-2 text-sm">{item.votes.toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
