import { Link } from 'react-router';
import { VotesResponse } from '../common/types';
import { VoteStatsView } from './VoteStatsView';

export type NavBarProps = {
  votes?: VotesResponse[];
};

export function NavBar({ votes }: NavBarProps) {
  return (
    <nav className="sticky top-0 z-50 bg-primary flex items-center justify-between py-2 px-4">
      {/* Brand / Site Name */}
      <Link
        to="/"
        className="text-white font-bold flex items-center gap-2 hover:text-secondary transition"
      >
        <div className="flex items-center justify-center bg-white rounded-sm p-1">
          <img
            src="https://cdn.aiescape.io/real-fake-news/icon.svg"
            alt="Real Fake News Logo"
            className="h-6 sm:h-8 w-auto"
          />
        </div>
        <span className="text-white text-md sm:text-xl">Real Fake News</span>
      </Link>

      {/* Right side: stats, login, etc. */}
      <div>
        <VoteStatsView votes={votes} />
      </div>
    </nav>
  );
}
