import React from 'react';
import { BadgeToast } from './BadgeToast';
import { BadgeTierAchievedResponse } from '../../common/types';

type BadgeToastContainerProps = {
  badges: BadgeTierAchievedResponse[];
  onClose: (index: number) => void;
};

export function BadgeToastContainer({ badges, onClose }: BadgeToastContainerProps) {
  return (
    <div className="fixed top-2 right-2 z-50 w-72 flex flex-col space-y-1">
      {badges.map((badge, i) => (
        <BadgeToast
          key={`${badge.badge.name}-${badge.name}`}
          badge={badge}
          onClose={() => onClose(i)}
          duration={(4000 / badges.length) * (i + 1)}
        />
      ))}
    </div>
  );
}
