import { Link } from 'react-router';
import { CategoryWithCount } from '../common/types';
import { useMemo } from 'react';
import { getCategories } from '../common/api/categories';
import { useQuery } from '@tanstack/react-query';
import { LoadingOrError } from './LoadingOrError';
import { Container } from './Container';
import { MainLink } from './MainLink';

/**
 * Capitalize each word in a category's name
 * Example: "strange news" -> "Strange News"
 */
export function formatCategoryName(name: string): string {
  return name
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export type CategoryItemProps = {
  category: CategoryWithCount;
};

export function CategoryItem(props: CategoryItemProps) {
  const { category } = props;
  const formattedName = useMemo(() => formatCategoryName(category.name), [category]);

  return (
    <Container className="grid grid-cols-1 gap-4">
      <div>
        {/* Category Name */}
        <h2 className="text-xl font-bold text-dark mb-2">{formattedName}</h2>

        {/* Article Stats */}
        <div className="text-dark text-sm">
          <p>
            <strong>{category.articlesCount + category.fakeArticlesCount}</strong> articles (
            {category.articlesCount} real, {category.fakeArticlesCount} fake)
          </p>
        </div>
      </div>

      {/* "View" Link */}
      <MainLink to={`/vote/category/${category.name}`} variant="secondary">
        <span className="text-lg font-semibold">Play</span>
      </MainLink>
    </Container>
  );
}

// export interface CategoryListProps {
//   categories: CategoryWithCount[];
// }

export function CategoryList() {
  const {
    data: categories,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ['categories'],
    queryFn: () => getCategories(),
    placeholderData: (prev) => prev,
    refetchOnWindowFocus: false,
    staleTime: 0,
  });

  const loading = isLoading && !error;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      {loading || error ? (
        <div className="col-span-2">
          <LoadingOrError loading={loading} error={error} retry={refetch} />
        </div>
      ) : categories ? (
        categories.map((category) => <CategoryItem key={category.uuid} category={category} />)
      ) : null}
    </div>
  );
}
