import { Icon } from '@cloudscape-design/components';
import { LoadingOrError } from './LoadingOrError';
import { MainButton } from './MainButton';

export type GuessButtonsProps = {
  onGuess: (guess: string) => void;
  enabled?: boolean;
  loading?: boolean;
  error?: Error | null;
  reset: () => void;
};

export function GuessButtons(props: GuessButtonsProps) {
  const { onGuess, enabled, loading, error, reset } = props;

  /**
   * We use a grid with 2 columns at `sm` breakpoint:
   *  - row 1 col 1: Real
   *  - row 1 col 2: Fake
   *  - row 2 col-span-2: Skip
   * On smaller screens (< sm), this is effectively 1 column, so Real, Fake, Skip stack vertically.
   */

  return (
    <div className="grid grid-cols-1 gap-4">
      <div className="grid grid-cols-2 gap-4">
        <MainButton variant="primary" onClick={() => onGuess('fake')} disabled={!enabled}>
          <div className="flex items-center justify-center gap-2">
            <Icon name="arrow-left" />
            <span className="text-lg">Fake</span>
          </div>
        </MainButton>
        <MainButton variant="secondary" onClick={() => onGuess('real')} disabled={!enabled}>
          <div className="flex items-center justify-center gap-2">
            <span className="text-lg">Real</span>
            <Icon name="arrow-right" />
          </div>
        </MainButton>
      </div>
      <div className="grid grid-cols-3 gap-4 mb-4 col-span-1">
        <MainButton variant="accent" onClick={() => onGuess('skip')} disabled={!enabled}>
          <span className="text-md">Skip</span>
        </MainButton>
        {error ? (
          <div className="flex justify-center col-span-3">
            <LoadingOrError error={error} retry={reset} hideText />
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
}
