import { UserStats } from '../common/types';
import { KeyValuePair } from './KeyValuePair';

export type UserStatsListProps = {
  stats: UserStats;
};

export function UserStatsList({ stats }: UserStatsListProps) {
  return (
    <div>
      <KeyValuePair title="Votes" value={stats.totalVotes.toLocaleString()} />
      <KeyValuePair title="Correct" value={stats.totalCorrect.toLocaleString()} />
      <KeyValuePair title="Best Accuracy" value={`${stats.bestAccuracy.toFixed(0)}%`} />
      <KeyValuePair title="Best Streak" value={stats.bestStreak.toLocaleString()} />
      {stats.lastPlayedDate && (
        <KeyValuePair
          title="Last Played"
          value={new Date(stats.lastPlayedDate).toLocaleDateString()}
        />
      )}
    </div>
  );
}
