import { BadgeTierAchievedResponse } from '../../common/types';
import { StackedContainer } from '../StackedContainer';

type BadgeIconProps = {
  badge: BadgeTierAchievedResponse;
};

export function BadgeIcon({ badge }: BadgeIconProps) {
  // TODO: add more thresholds
  let thresholdText = `Threshold: ${badge.threshold}`;

  switch (badge.badge.name) {
    case 'Fake Buster':
      thresholdText = `${badge.threshold} correct guesses`;
      break;
    case 'Spot-On':
      thresholdText = `${badge.threshold}% accuracy`;
      break;
    case 'Hot Streak':
      thresholdText = `${badge.threshold} correct guesses in a row`;
      break;
  }

  // TODO default icon
  const icon =
    badge.iconUrl ??
    badge.badge.iconUrl ??
    'https://cdn.aiescape.io/real-fake-news/badges/verified-icon.svg';
  let color = 'border-secondary';

  switch (badge.name) {
    case 'Diamond':
      color = 'border-teal-500';
      break;
    case 'Platinum':
      color = 'border-green-500';
      break;
    case 'Gold':
      color = 'border-yellow-500';
      break;
    case 'Silver':
      color = 'border-gray-500';
      break;
    case 'Bronze':
      color = 'border-orange-500';
      break;
  }

  return (
    <StackedContainer className={`text-dark border-2 ${color}`}>
      <div className="flex flex-row gap-2">
        <img src={icon} alt={badge.badge.name} className="w-10 h-10" />
        <div>
          <h3 className="font-bold text-md">
            {badge.badge.name} - {badge.name}
          </h3>
          {/* <p className="text-sm">{badge.badge.description || 'New badge unlocked!'}</p> */}
          <p className="text-xs">{thresholdText}</p>
        </div>
      </div>
    </StackedContainer>
  );
}
