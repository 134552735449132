import { TextLink } from '../../components/TextLink';
import { SnakeText } from '../../components/text/SnakeText';
import { BigQuotes } from '../../components/text/BigQuotes';
import { ColumnView } from '../../components/ColumnView';

export default function TermsOfService() {
  return (
    <ColumnView>
      <h1 className="text-3xl font-bold">
        Terms of Service for{' '}
        <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong>
      </h1>

      <p>
        Welcome to <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong>. By
        accessing or using our platform, you agree to be bound by these Terms of Service (
        <em>“Terms”</em>). If you do not agree with these Terms, please do not use our services.
      </p>

      <h2 className="text-xl font-bold">1. Eligibility</h2>
      <p>
        You must be at least 13 years of age to use{' '}
        <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong>. If you are
        under 18, you may only use our services under the supervision of a parent or legal guardian.
      </p>

      <h2 className="text-xl font-bold">2. User Accounts</h2>
      <p>
        You may be required to provide certain personal information, such as your email address and
        name, to create an account. You agree to provide accurate and complete information and to
        update it as necessary. You are solely responsible for maintaining the security of your
        account credentials and for all activities that occur under your account.
      </p>

      <h2 className="text-xl font-bold">3. Acceptable Use</h2>
      <p>
        You agree not to use{' '}
        <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong> for any unlawful
        or prohibited purpose, including but not limited to:
      </p>
      <ul className="list-disc list-inside ml-4">
        <li>Harassing or harming any individual.</li>
        <li>Posting or transmitting content that is unlawful, abusive, defamatory, or obscene.</li>
        <li>Violating any applicable laws or regulations.</li>
        <li>Accessing or attempting to access another user’s account without authorization.</li>
        <li>Introducing viruses or other harmful code.</li>
      </ul>

      <h2 className="text-xl font-bold">4. Intellectual Property</h2>
      <p>
        All content on <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong>,
        including but not limited to text, graphics, logos, and software, is the property of{' '}
        <strong className="px-1 py-0 bg-dark text-light"> AI Escape</strong> or its content
        suppliers, and is protected by applicable intellectual property laws. You agree not to
        reproduce, distribute, or create derivative works from any content found on our platform
        without express written permission.
      </p>

      <h2 className="text-xl font-bold">5. Content Disclaimer</h2>
      <p>
        <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong> presents
        headlines, both <SnakeText>fake</SnakeText> and real, as part of a game. While we attempt to
        provide accurate information for real headlines, we cannot guarantee the completeness or
        timeliness of external sources. The <em className="underline">fake</em> headlines are
        generated by AI and are intentionally inaccurate.
      </p>

      <h2 className="text-xl font-bold">6. Disclaimer of Warranties</h2>
      <p>
        Our platform is provided on an <em>“as is”</em> and <em>“as available”</em> basis. We make
        no warranties or representations of any kind, whether express or implied, regarding the
        operation of our platform, the accuracy of content, or the reliability of any information
        provided.
      </p>

      <h2 className="text-xl font-bold">7. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law,{' '}
        <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong> and{' '}
        <strong className="px-1 py-0 bg-dark text-light">AI Escape</strong> shall not be liable for
        any direct, indirect, incidental, special, or consequential damages arising out of or in
        connection with your use of, or inability to use, our services.
      </p>

      <h2 className="text-xl font-bold">8. Indemnification</h2>
      <p>
        You agree to indemnify and hold harmless{' '}
        <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong> and{' '}
        <strong className="px-1 py-0 bg-dark text-light">AI Escape</strong>, its affiliates,
        directors, employees, and agents from and against any claims, liabilities, damages,
        judgments, or expenses arising out of your violation of these Terms.
      </p>

      <h2 className="text-xl font-bold">9. Termination</h2>
      <p>
        We reserve the right to suspend or terminate your access to{' '}
        <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong> at any time,
        without prior notice, for any reason, including if we believe you have violated these Terms.
      </p>

      <h2 className="text-xl font-bold">10. Changes to Terms</h2>
      <p>
        We may update or modify these Terms from time to time. Any changes will be effective when
        posted. Your continued use of our services after the posting of any modified Terms shall
        constitute your acceptance of those changes.
      </p>

      <h2 className="text-xl font-bold">11. Governing Law</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws of the United
        States. You agree to submit to the personal jurisdiction of the courts located within the
        U.S. for the resolution of any disputes.
      </p>

      <h2 className="text-xl font-bold">12. Contact Us</h2>
      <p>
        For questions or concerns about these Terms, please email us at{' '}
        <TextLink variant="primary" to="mailto:max@aiescape.io" className="underline">
          max@aiescape.io
        </TextLink>
        .
      </p>

      <p className="mt-4">
        <em>
          Thank you for reading our Terms of Service, and we hope you enjoy{' '}
          <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong>!
        </em>
      </p>
    </ColumnView>
  );
}
