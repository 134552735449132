import { Link } from 'react-router';
import { CategoryList } from '../../components/CategoryList';
import { MainLink } from '../../components/MainLink';
import { SnakeText } from '../../components/text/SnakeText';
import { BigQuotes } from '../../components/text/BigQuotes';
import { TextLink } from '../../components/TextLink';
import { ColumnView } from '../../components/ColumnView';

export default function View() {
  return (
    <ColumnView>
      <h1 className="text-2xl font-bold">
        Welcome to <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong>!
      </h1>

      <p>
        Think you can tell a <strong>real</strong> headline from a <SnakeText>fake</SnakeText> one?
        At <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong>, it’s your
        job to guess whether a headline is <em className="underline">literally</em> fake or not.
      </p>

      <p>
        There’s a lot of <BigQuotes>fake news</BigQuotes> out there. But what that means to everyone
        is different. Determining the <em>truth</em> is a difficult task! However, here it’s{' '}
        <strong>pretty simple</strong>: We’ll give you a headline, and you’ll have to guess whether
        it’s a real, bona fide news headline, or{' '}
        <strong className="px-1 py-0 bg-primary text-light">real fake news</strong> written by AI.
      </p>

      <p>Ready to play?</p>

      <div className="flex justify-center mt-2 mb-2">
        <MainLink to="/vote" variant="primary" className="w-64">
          <span className="text-lg font-semibold">Play Now</span>
        </MainLink>
      </div>

      <p>
        You can also learn more about the{' '}
        <strong className="px-1 py-0 bg-primary text-light">Real Fake News</strong> project in our{' '}
        <Link to="/about" className="underline font-semibold hover:text-primary transition">
          about page
        </Link>
        .
      </p>

      <p>
        Check out the{' '}
        <TextLink to="/leaderboard" variant="primary" className="underline">
          leaderboard
        </TextLink>{' '}
        to see how you stack up against other players.
      </p>

      <p>If you'd prefer to play with articles from a specific topic, check out the list below.</p>

      <div>
        <h2 className="text-2xl font-bold">Topics</h2>
        <CategoryList />
      </div>
    </ColumnView>
  );
}
