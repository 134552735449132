// useArticleQueue.ts
import { useState, useCallback, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getRandomArticle, getRandomCategoryArticle } from '../common/api/articles';
import { RandomArticleResponse } from '../common/types';

/**
 * A hook that maintains a queue of RandomArticleResponse.
 * - Always tries to fill the queue to `queueSize` articles by fetching them one by one.
 * - The user sees the first article ASAP, no need to wait for the entire queue to fill.
 */
export function useArticleQueue(queueSize = 3, category?: string) {
  // This array holds the "ready" articles
  const [queue, setQueue] = useState<RandomArticleResponse[]>([]);

  // Single-article query:
  // "enabled" triggers another fetch whenever queue.length < queueSize.
  const query = useQuery({
    queryKey: ['articles', 'random', category],
    queryFn: async () => {
      if (queue.length >= queueSize) {
        return null;
      }

      if (category) {
        return await getRandomCategoryArticle(category);
      }

      return await getRandomArticle();
    },
    enabled: queue.length < queueSize,
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  // Reset the queue when the category changes
  useEffect(() => {
    setQueue([]);
  }, [category]);

  useEffect(() => {
    if (queue.length < queueSize) {
      query.refetch();
    }
  }, [queue]);

  useEffect(() => {
    if (query.data) {
      const data = query.data;
      setQueue((prev) => [...prev, data]);
    }
  }, [query.data]);

  /**
   * Pops (consumes) the first article from the queue and returns it.
   * Because "enabled" is (queue.length < queueSize), popping
   * triggers the queue to drop below the threshold, so the query
   * automatically fetches another article in the background.
   */
  const popArticle = useCallback(() => {
    if (queue.length === 0) return undefined;
    const [head, ...rest] = queue;
    setQueue(rest);

    return head;
  }, [queue]);

  return {
    queue,
    popArticle,
    refetch: query.refetch,
    loading: queue.length === 0 && query.isFetching && !query.error,
    error: query.error,
  };
}
