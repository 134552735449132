import { getRandomArticle } from '../../common/api/articles';
import { useQuery } from '@tanstack/react-query';
import { VoteView } from '../../components/VoteView';
import { VotesResponse } from '../../common/types';
import { useArticleQueue } from '../../common/useArticleQueue';

export type VotePageProps = {
  setLiveVotes: React.Dispatch<React.SetStateAction<VotesResponse[] | undefined>>;
};

export default function VotePage(props: VotePageProps) {
  const { setLiveVotes } = props;

  const { queue, popArticle, loading, refetch, error } = useArticleQueue(3);

  // The first article in the queue is the "current" article
  const currentArticle = queue.length > 0 ? queue[0] : undefined;

  // This can be triggered when the user finishes guessing
  function handleNextArticle() {
    popArticle(); // Remove the top article, triggers re-fetch
  }

  return (
    <div
      className="
        container mx-auto max-w-xl 
        min-h-[80dvh]
        flex flex-col 
        items-center justify-center
      "
    >
      <VoteView
        article={currentArticle}
        enabled={queue.length > 0}
        loading={loading}
        error={error}
        retry={refetch}
        setLiveVotes={setLiveVotes}
        onGuessComplete={handleNextArticle}
      />
    </div>
  );
}
