import { Link } from 'react-router';
import { CategoryList } from '../../components/CategoryList';
import { MainLink } from '../../components/MainLink';
import { SnakeText } from '../../components/text/SnakeText';
import { BigQuotes } from '../../components/text/BigQuotes';
import { useQuery } from '@tanstack/react-query';
import { getLeaderboard } from '../../common/api/leaderboard';
import { LoadingOrError } from '../../components/LoadingOrError';
import { LeaderboardList } from '../../components/LeaderboardList';
import { Container } from '../../components/Container';
import { ColumnView } from '../../components/ColumnView';

export default function View() {
  const { isPending, isFetching, error, data, refetch } = useQuery({
    queryKey: ['leaderboard'],
    queryFn: async () => await getLeaderboard(),
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  const loading = (isPending || isFetching) && !error;

  return (
    <ColumnView>
      <h1 className="text-2xl font-bold">Leaderboard</h1>

      <Container className="flex flex-col gap-4">
        <h2 className="text-xl font-bold">Players</h2>
        <p className="text-sm text-gray-500">Top 20 players by skill score</p>
        {loading || error || !data ? (
          <LoadingOrError loading={loading} error={error} retry={refetch} />
        ) : (
          <LeaderboardList items={data} />
        )}
      </Container>

      <h2 className="text-xl font-bold">How it works</h2>

      <p>
        Our leaderboard ranks players by a <BigQuotes>Skill Score</BigQuotes> that considers both
        how <strong>accurate</strong> they are and <em>how many votes</em> they’ve cast. A new user
        with just a few votes shouldn’t outrank a seasoned player who’s demonstrated{' '}
        <em className="underline">consistent</em> accuracy. The score is based on a{' '}
        <SnakeText>Bayesian</SnakeText> model that starts you near the average and updates as you
        cast more votes.
      </p>

      <p>
        The more you vote, the more <em className="underline">confident</em> we are in your skill
        level. <strong className="px-1 py-0 bg-primary text-light">Correct</strong> guesses boost
        your skill score, while{' '}
        <strong className="px-1 py-0 bg-secondary text-light">incorrect</strong> guesses lower it.
        If you have fewer votes, your skill score sticks closer to 50% (the global average). As you
        rack up votes, it moves up or down based on your performance.
      </p>
    </ColumnView>
  );
}
