import { ReactNode } from 'react';

export type StackedContainerProps = {
  children?: ReactNode;
  className?: string;
};

export function StackedContainer(props: StackedContainerProps) {
  const { children, className } = props;

  return <div className={`p-4 bg-background rounded shadow ${className || ''}`}>{children}</div>;
}
