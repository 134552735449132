import { User, UserUpdateRequest, PublicUser } from '../types';
import axiosInstance from './axiosInstance';

export async function getUser() {
  const response = await axiosInstance.get('/user');

  return response.data as User;
}

export async function getPublicUser(username: string) {
  const response = await axiosInstance.get(`/users/${username}`);

  return response.data as PublicUser;
}

export async function updateUser(request: UserUpdateRequest) {
  const response = await axiosInstance.put(`/user`, request);

  return response.data as User;
}
