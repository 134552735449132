import { VoteResponse } from '../common/types';
import { Link } from 'react-router';
import { MainButton } from './MainButton';
import { TextLink } from './TextLink';

export type VoteRealResultProps = {
  result: VoteResponse;
};

export function VoteRealResult(props: VoteRealResultProps) {
  const { result } = props;
  const { guess, correct } = result;

  return (
    <div className="grid grid-cols-1 gap-4">
      <div className="text-center grid grid-cols-1">
        <h1 className="text-4xl font-bold mb-2 text-dark animate-bounce">REAL!</h1>
        <p className="text-dark">You guessed: {guess.toUpperCase()}</p>
        <p className={`font-semibold ${correct ? 'text-green-700' : 'text-red-600'}`}>
          {correct ? 'Correct!' : 'Incorrect'}
        </p>
      </div>
      <div className="text-left mb-4 shadow-md p-4 rounded bg-white/80">
        {result.article?.author && (
          <p className="text-dark mb-2 font-semibold">Author: {result.article?.author}</p>
        )}
        {result.article?.source && (
          <p className="text-sm mb-2">Source: {result.article?.source?.name}</p>
        )}
        <p className="text-dark mb-2">{result.article?.description}</p>
        <TextLink to={result.article?.url || ''} external className="pressable">
          <span className="text-blue-500 font-normal pressable">Read more</span>
        </TextLink>
      </div>
    </div>
  );
}
