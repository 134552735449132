import { VoteResponse } from '../common/types';
import { MainButton } from './MainButton';

export type VoteFakeResultProps = {
  result: VoteResponse;
};

export function VoteFakeResult(props: VoteFakeResultProps) {
  const { result } = props;
  const { guess, correct } = result;

  return (
    <div className="grid grid-cols-1 gap-4">
      <div className="text-center grid grid-cols-1">
        <h1 className="text-4xl font-bold mb-2 text-dark animate-bounce">FAKE!</h1>
        <p className="text-dark">You guessed: {guess.toUpperCase()}</p>
        <p className={`font-semibold ${correct ? 'text-green-700' : 'text-red-600'}`}>
          {correct ? 'Correct!' : 'Incorrect'}
        </p>
        <div className="text-left">
          <p className="text-dark font-semibold">Why is it fake?</p>
          <p className="text-dark">{result.fakeArticle?.rationale}</p>
        </div>
      </div>
      <div className="text-left mb-4 shadow-md p-4 rounded bg-white/80">
        <p className="text-dark mb-2 font-semibold">
          Author: AI{' '}
          {result.fakeArticle?.source?.name ? `(${result.fakeArticle?.source?.name})` : ''}
        </p>
        {result.fakeArticle?.difficultyLevel && (
          <p className="text-sm mb-2">Difficulty: {result.fakeArticle?.difficultyLevel}/10</p>
        )}
        <p className="text-dark mb-2 text-sm">{result.fakeArticle?.description}</p>
      </div>
    </div>
  );
}
