import { getRandomCategoryArticle } from '../../../common/api/articles';
import { useQuery } from '@tanstack/react-query';
import { LoadingOrError } from '../../../components/LoadingOrError';
import { VoteView } from '../../../components/VoteView';
import { useParams } from 'react-router';
import { getCategoryByName } from '../../../common/api/categories';
import { useMemo } from 'react';
import { VotesResponse } from '../../../common/types';
import { useArticleQueue } from '../../../common/useArticleQueue';

export type VoteCategoryPageProps = {
  setLiveVotes: React.Dispatch<React.SetStateAction<VotesResponse[] | undefined>>;
};

export default function VoteCategoryPage(props: VoteCategoryPageProps) {
  const { setLiveVotes } = props;
  const { name } = useParams();

  const catQuery = useQuery({
    queryKey: ['categories', name],
    queryFn: async () => await getCategoryByName(name ?? ''),
    staleTime: 0,
    placeholderData: (prev) => prev,
    refetchOnWindowFocus: false,
  });

  const { queue, popArticle, loading, refetch, error } = useArticleQueue(3, name);

  const formattedName = useMemo(() => {
    return name
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }, [name]);

  const combinedLoading = (catQuery.isPending && !catQuery.error) || loading;
  const combinedError = error || catQuery.error;

  // The first article in the queue is the "current" article
  const currentArticle = queue.length > 0 ? queue[0] : undefined;

  // This can be triggered when the user finishes guessing
  function handleNextArticle() {
    popArticle(); // Remove the top article, triggers re-fetch
  }

  return (
    <div
      className="
        container mx-auto max-w-xl 
        min-h-[80dvh]
        flex flex-col 
        items-center justify-center
      "
    >
      {catQuery.data ? (
        <div className="flex flex-col items-left justify-left w-full">
          <h1 className="text-xl font-bold mx-4">{formattedName}</h1>
        </div>
      ) : null}
      <VoteView
        article={currentArticle}
        enabled={!combinedLoading}
        loading={combinedLoading}
        error={combinedError}
        retry={() => {
          catQuery.refetch();
          refetch();
        }}
        setLiveVotes={setLiveVotes}
        onGuessComplete={handleNextArticle}
      />
    </div>
  );
}
