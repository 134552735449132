import { VoteResponse } from '../common/types';
import { VoteFakeResult } from './VoteFakeResult';
import { VoteRealResult } from './VoteRealResult';

export type VoteResultViewProps = {
  result: VoteResponse;
};

export function VoteResultView(props: VoteResultViewProps) {
  const { result } = props;

  return (
    <div className="text-center">
      {result.article ? <VoteRealResult result={result} /> : <VoteFakeResult result={result} />}
    </div>
  );
}
