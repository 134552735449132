import { useEffect } from 'react';
import { BadgeTierAchievedResponse } from '../../common/types';
import { BadgeIcon } from './BadgeIcon';

type BadgeToastProps = {
  badge: BadgeTierAchievedResponse;
  onClose: () => void;
  duration?: number; // optional, default to 3000 ms
};

export function BadgeToast({ badge, onClose, duration = 3000 }: BadgeToastProps) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [onClose, duration]);

  return (
    <div className="animate-fadeInUp">
      <BadgeIcon badge={badge} />
    </div>
  );
}
