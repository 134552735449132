import { VoteRequest, VoteResponse, VotesResponse } from '../types';
import axiosInstance from './axiosInstance';

export async function createVote(vote: VoteRequest) {
  const response = await axiosInstance.post('/votes', vote);

  return response.data as VoteResponse;
}

export async function getVotes() {
  const response = await axiosInstance.get('/votes');

  return response.data as VotesResponse[];
}
