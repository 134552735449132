import { ReactNode } from 'react';

export type ContainerProps = {
  children?: ReactNode;
  className?: string;
};

export function Container(props: ContainerProps) {
  const { children, className } = props;

  return (
    <div className={`p-4 bg-background rounded shadow m-4 ${className || ''}`}>{children}</div>
  );
}
