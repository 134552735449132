import { useMemo } from 'react';
import { PublicUser, VotesResponse } from '../../common/types';
import { calculateVoteStats } from '../../common/stats/votes';
import { Link, useParams } from 'react-router';
import { LoadingOrError } from '../../components/LoadingOrError';
import { useQuery } from '@tanstack/react-query';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { getPublicUser } from '../../common/api/user';
import { KeyValuePair } from '../../components/KeyValuePair';
import { Container } from '../../components/Container';
import { MainLink } from '../../components/MainLink';
import { BadgeIcon } from '../../components/badges/BadgeIcon';
import { filterMaxBadgeTier } from '../../common/badges';
import { UserStatsList } from '../../components/UserStatsList';
import { ColumnView } from '../../components/ColumnView';
import { LoadingView } from '../../components/LoadingView';

export type ProfileViewProps = {
  user: PublicUser;
};

function ProfileView({ user }: ProfileViewProps) {
  const maxTierBadges = useMemo(() => {
    return filterMaxBadgeTier(user.badges ?? []);
  }, [user]);

  return (
    <ColumnView columns={2}>
      <div>
        <Container>
          <h1 className="text-2xl font-bold mb-4 text-dark">{user.username}</h1>
          <KeyValuePair title="Joined" value={new Date(user.createdAt).toLocaleDateString()} />
          {user.stats && <UserStatsList stats={user.stats} />}
        </Container>
      </div>
      <div>
        <Container>
          <h1 className="text-xl font-bold mb-4 text-dark">Badges</h1>
          {maxTierBadges && maxTierBadges.length > 0 ? (
            <div className="flex flex-col gap-2">
              {maxTierBadges.map((badge) => (
                <BadgeIcon key={`${badge.badge.name}-${badge.name}`} badge={badge} />
              ))}
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              <p className="text-dark">No badges yet</p>
            </div>
          )}
        </Container>
      </div>
    </ColumnView>
  );
}

export default function View() {
  const { username } = useParams();

  const {
    isPending,
    isFetching,
    error,
    data: user,
    refetch,
  } = useQuery({
    queryKey: ['users', username],
    queryFn: async () => await getPublicUser(username ?? ''),
    staleTime: 0,
    refetchOnWindowFocus: false,
    enabled: !!username,
  });

  const loading = (isPending || isFetching) && !error;

  return (
    <LoadingView loading={loading} error={error} retry={refetch}>
      {user && <ProfileView user={user} />}
    </LoadingView>
  );
}
