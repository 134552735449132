import { CategoryWithCount } from '../types';
import axiosInstance from './axiosInstance';

export async function getCategories() {
  const response = await axiosInstance.get('/categories');

  return response.data as CategoryWithCount[];
}

export async function getCategoryByName(name: string) {
  const response = await axiosInstance.get(`/categories/${name}`);

  return response.data as CategoryWithCount;
}
