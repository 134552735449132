import './big-quotes.css';

type BigQuotesProps = {
  children: string;
};

export function BigQuotes({ children }: BigQuotesProps) {
  return (
    <span className="big-quotes">
      <span className="quote-start text-2xl">“</span>
      <span className="big-quotes-text text-lg">{children}</span>
      <span className="quote-end text-2xl">”</span>
    </span>
  );
}
