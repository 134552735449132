export type MainInputProps = {
  value: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
};

export function MainInput(props: MainInputProps) {
  const { value, onChange, className } = props;

  return (
    <input
      type="text"
      value={value}
      onChange={onChange}
      className={`border border-gray-300 rounded-md p-2 ${className ?? ''}`}
    />
  );
}
