import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import TinderCard from 'react-tinder-card';

import { createVote } from '../common/api/votes';
import {
  VoteResponse,
  RandomArticleResponse,
  VotesResponse,
  BadgeTierAchievedResponse,
} from '../common/types';

import { CategoryIcon } from './CategoryItem';
import { VoteResultView } from './VoteResultView';
import { Container } from './Container';
import { GuessButtons } from './GuessButtons';
import { MainButton } from './MainButton';
import { LoadingOrError } from './LoadingOrError';
import { BadgeToastContainer } from './badges/BadgeToastContainer';

declare type API = {
  swipe(dir?: 'left' | 'right' | 'up' | 'down'): Promise<void>;
  restoreCard(): Promise<void>;
}

export type VoteViewProps = {
  article?: RandomArticleResponse;
  enabled?: boolean;
  setLiveVotes: React.Dispatch<React.SetStateAction<VotesResponse[] | undefined>>;
  loading?: boolean;
  error?: Error | null;
  retry: () => void;
  onGuessComplete?: () => void;
};

export function VoteView(props: VoteViewProps) {
  const { article, enabled, setLiveVotes, loading, error, retry, onGuessComplete } = props;

  const resultCurrent = useRef<API | null>(null);
  const voteCurrent = useRef<API | null>(null);

  const [badgePopups, setBadgePopups] = useState<BadgeTierAchievedResponse[]>([]);

  const vote = useMutation({
    mutationFn: async ({ guess }: { guess: string }) => {
      return await createVote({
        uuid: article?.uuid ?? '',
        guess,
      });
    },
    onSuccess: (resp: VoteResponse) => {
      // 1. Update local "liveVotes"
      const newVote = {
        uuid: resp.uuid,
        guess: resp.guess,
        correct: resp.correct,
        articleUuid: resp.article?.uuid,
        fakeArticleUuid: resp.fakeArticle?.uuid,
        createdAt: new Date().toISOString(),
      } as VotesResponse;
      setLiveVotes((lv) => (lv ? [...lv, newVote] : [newVote]));

      // 2. If user chose skip, reset and fetch next article
      if (resp.guess === 'skip') {
        vote.reset();
        onGuessComplete?.();
      }

      // 3. If there are new badges, add them to badgePopups
      if (resp.newBadges && resp.newBadges.length > 0) {
        setBadgePopups((prev) => [...prev, ...(resp.newBadges || [])]);
      }
    },
  });

  const reset = () => {
    vote.reset();
    onGuessComplete?.();
  };

  const handleAction = async (guess: string) => {
    if (!enabled || vote.isPending || vote.error) return;

    if (voteCurrent.current) {
      if (guess === 'real') {
        await voteCurrent.current.swipe('right');
      } else if (guess === 'fake') {
        await voteCurrent.current.swipe('left');
      } else {
        await vote.mutateAsync({ guess });
      }
    }
  };

  // function to remove a single badge from the array
  const handleCloseBadge = (idx: number) => {
    setBadgePopups((prev) => prev.filter((_, i) => i !== idx));
  };

  const title = useMemo(() => {
    // Capitalize first letter of each word
    return article?.title
      ?.split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }, [article]);

  return (
    <div className="relative flex flex-col justify-between h-full w-full">
      {/* Badge toast container */}
      <BadgeToastContainer badges={badgePopups} onClose={handleCloseBadge} />

      {vote.data && vote.data.guess !== 'skip' && article ? (
        <div className="flex-1 flex flex-col items-center justify-center gap-4 px-4">
          <TinderCard
            ref={resultCurrent}
            key={`${article.uuid}-result-card`}
            onSwipe={reset}
            swipeRequirementType="position"
            swipeThreshold={100}
          >
            <Container key={`${article.uuid}-result-content`} className="animate-fadeInUp">
              <h2 className="text-xl font-bold mb-2 text-dark">{title}</h2>
              {article.categories && (
                <div className="mb-4 flex flex-wrap gap-2">
                  {article.categories.map((category) => (
                    <CategoryIcon key={category.uuid} category={category} />
                  ))}
                </div>
              )}
              <VoteResultView result={vote.data} />
            </Container>
          </TinderCard>
          <div className="px-4 mx-4 w-full">
            <MainButton onClick={reset} variant="secondary" className="w-full">
              <span className="text-lg">Next</span>
            </MainButton>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-2">
          <div className="px-4 mx-4">
            {loading || error || !article ? (
              <div className="flex flex-col items-center justify-center h-64">
                <LoadingOrError loading={loading} error={error} retry={retry} />
              </div>
            ) : (
              <TinderCard
                ref={voteCurrent}
                key={`${article.uuid}-vote-card`}
                onSwipe={async (direction: string) => {
                  if (direction === 'right') {
                    await vote.mutateAsync({ guess: 'real' });
                  } else if (direction === 'left') {
                    await vote.mutateAsync({ guess: 'fake' });
                  }
                }}
                preventSwipe={['down', 'up']}
                swipeRequirementType="position"
                swipeThreshold={100}
              >
                <Container key={`${article.uuid}-vote-content`} className="animate-fadeInUp">
                  <h2 className="text-2xl font-bold mb-2 text-dark">{title}</h2>
                  {article.categories && (
                    <div className="mb-4 flex flex-wrap gap-2">
                      {article.categories.map((category) => (
                        <CategoryIcon key={category.uuid} category={category} />
                      ))}
                    </div>
                  )}
                </Container>
              </TinderCard>
            )}
          </div>
          <div className="px-4 mx-4">
            <GuessButtons
              onGuess={handleAction}
              enabled={enabled && !vote.isPending && !vote.error}
              loading={vote.isPending}
              error={vote.error}
              reset={reset}
            />
          </div>
        </div>
      )}
    </div>
  );
}
