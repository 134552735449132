import { BadgeTierAchievedResponse } from './types';

export function badgeRank(badge: BadgeTierAchievedResponse) {
  let rank = 0;

  switch (badge.name) {
    case 'Bronze':
      rank = 1;
      break;
    case 'Silver':
      rank = 2;
      break;
    case 'Gold':
      rank = 3;
      break;
    case 'Platinum':
      rank = 4;
      break;
    case 'Diamond':
      rank = 5;
      break;
  }

  return rank;
}

export function filterMaxBadgeTier(badges: BadgeTierAchievedResponse[]) {
  const badgeMaxTier = new Map<string, number>();

  for (const badge of badges) {
    const rank = badgeRank(badge);

    if (badgeMaxTier.has(badge.badge.name)) {
      badgeMaxTier.set(badge.badge.name, Math.max(badgeMaxTier.get(badge.badge.name) ?? 0, rank));
    } else {
      badgeMaxTier.set(badge.badge.name, rank);
    }
  }

  const onlyMaxTierBadges = badges.filter(
    (badge) => badgeRank(badge) === badgeMaxTier.get(badge.badge.name),
  );

  return onlyMaxTierBadges;
}
