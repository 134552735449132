import { VotesResponse } from '../types';

export type VoteStats = {
  total: number;
  correct: number;
  incorrect: number;
  accuracy: number;
  fakeJudged: number;
  fakeCorrect: number;
  fakeIncorrect: number;
  fakeAccuracy: number;
  realJudged: number;
  realCorrect: number;
  realIncorrect: number;
  realAccuracy: number;
};

export function calculateVoteStats(votes?: VotesResponse[]) {
  if (!votes) {
    return null;
  }

  let judged = 0;
  let correct = 0;
  let fakeJudged = 0;
  let fakeCorrect = 0;
  let realJudged = 0;
  let realCorrect = 0;

  for (const v of votes) {
    if (v.guess !== 'real' && v.guess !== 'fake') {
      // skips votes that are not real or fake, like skip
      continue;
    }
    judged++;

    if (v.correct) {
      correct++;
    }

    if (v.fakeArticleUuid) {
      fakeJudged++;

      if (v.correct) {
        fakeCorrect++;
      }
    }

    if (v.articleUuid) {
      realJudged++;

      if (v.correct) {
        realCorrect++;
      }
    }
  }

  if (judged === 0) {
    return null;
  }

  return {
    total: judged,
    correct,
    incorrect: judged - correct,
    accuracy: Math.round((correct / judged) * 100),
    fakeJudged,
    fakeCorrect,
    fakeIncorrect: fakeJudged - fakeCorrect,
    fakeAccuracy: Math.round((fakeCorrect / fakeJudged) * 100),
    realJudged,
    realCorrect,
    realIncorrect: realJudged - realCorrect,
    realAccuracy: Math.round((realCorrect / realJudged) * 100),
  } as VoteStats;
}
