import React from 'react';

export type MainButtonProps = {
  onClick?: () => void;
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'light' | 'accent';
  disabled?: boolean;
  className?: string;
};

export function MainButton(props: MainButtonProps) {
  const { onClick, children, variant = 'primary', disabled = false, className } = props;

  // We'll base our styling on the variant
  // For example, "primary" is pastel pink, "secondary" might be pastel blue
  const baseStyles = `
    px-4 py-2 rounded shadow font-semibold text-dark
    disabled:opacity-50 disabled:cursor-not-allowed
    hover:brightness-110 transition
    text-center
    w-full
    ${className ?? ''}
  `;
  const variantStyles =
    variant === 'primary'
      ? 'bg-secondary hover:bg-accent' // e.g., pink -> peach on hover
      : variant === 'secondary'
      ? 'bg-primary hover:bg-accent'
      : variant === 'accent'
      ? 'bg-accent hover:bg-light'
      : 'bg-light hover:bg-accent';

  return (
    <button onClick={onClick} disabled={disabled} className={`${baseStyles} ${variantStyles}`}>
      {children}
    </button>
  );
}
