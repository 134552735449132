import { useEffect } from 'react';
import { useLocation } from 'react-router';

type ScrollToTopProps = {
  scrollContainerRef: React.RefObject<HTMLDivElement | null>;
};

export function ScrollToTop({ scrollContainerRef }: ScrollToTopProps) {
  const { pathname } = useLocation();

  useEffect(() => {
    // Whenever the route changes, scroll the container to the top.
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: 0, behavior: 'auto' });
      // or scrollContainerRef.current.scrollTop = 0;
    }
  }, [pathname, scrollContainerRef]);

  return null;
}
