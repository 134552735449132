export default function MicrosoftIcon() {
  return (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path fill="#F25022" d="M0 0h9.474v9.474H0z" />
        <path fill="#00A4EF" d="M0 10.526h9.474V20H0z" />
        <path fill="#7FBA00" d="M10.526 0H20v9.474h-9.474z" />
        <path fill="#FFB900" d="M10.526 10.526H20V20h-9.474z" />
      </g>
    </svg>
  );
}
